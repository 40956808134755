require('prismjs/themes/prism-tomorrow.css');

exports.onRouteUpdate = () => {
  if (typeof window !== `undefined`) {
    window.scrollTo(0, 0);
  }
};

exports.shouldUpdateScroll = args => {
  return args.routerProps.location.href.indexOf('#') >= 0;
};
