module.exports = [{
      plugin: require('/var/lib/jenkins/workspace/bgm-landing-page-release/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2000,"backgroundColor":"transparent"},
    },{
      plugin: require('/var/lib/jenkins/workspace/bgm-landing-page-release/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-139151829-1"},
    },{
      plugin: require('/var/lib/jenkins/workspace/bgm-landing-page-release/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
