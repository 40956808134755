// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/var/lib/jenkins/workspace/bgm-landing-page-release/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("/var/lib/jenkins/workspace/bgm-landing-page-release/src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-eula-js": () => import("/var/lib/jenkins/workspace/bgm-landing-page-release/src/pages/eula.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-faq-js": () => import("/var/lib/jenkins/workspace/bgm-landing-page-release/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("/var/lib/jenkins/workspace/bgm-landing-page-release/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("/var/lib/jenkins/workspace/bgm-landing-page-release/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("/var/lib/jenkins/workspace/bgm-landing-page-release/src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-directions-js": () => import("/var/lib/jenkins/workspace/bgm-landing-page-release/src/pages/products/directions.js" /* webpackChunkName: "component---src-pages-products-directions-js" */),
  "component---src-pages-products-locations-js": () => import("/var/lib/jenkins/workspace/bgm-landing-page-release/src/pages/products/locations.js" /* webpackChunkName: "component---src-pages-products-locations-js" */),
  "component---src-pages-products-maps-js": () => import("/var/lib/jenkins/workspace/bgm-landing-page-release/src/pages/products/maps.js" /* webpackChunkName: "component---src-pages-products-maps-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/var/lib/jenkins/workspace/bgm-landing-page-release/.cache/data.json")

